import React, { useContext, useState } from "react";
import styles from "./CheckoutSummary.module.scss";
import {
  Country,
  Currency,
  Language,
  TerminalType,
  UnitType,
} from "../../../data/models/ContractTypes";
import {
  SalesForcePriceEntry,
  useCheckoutPricing,
} from "../../../data/dataCheckout";
import { RequiredValidator } from "../../Forms/validators/RequiredValidator";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { FormattedPercent } from "../../../components/FormattedPrice/FormattedPercent";
import { ProductOffer } from "../../../components/ProductOffer/ProductOffer";
import { GlobalContext } from "../../..";
import { QueryKey, Store, STORE_KEY } from "../../../data/Store";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../../Forms/Checkboxes";
import { Link } from "../../../components/Link/Link";
import { HiddenInput } from "../../Forms/HiddenInput";
import { Acquiring } from "../Acquiring/Acquiring";
import { AdditionalCosts } from "../AdditionalCosts";
import { TermsOfService } from "../TermsOfService";

const AVAILABLE_TERMINALS = Object.values(TerminalType);

export const TERMS_AND_CONDITIONS_URL: Record<Country, string> = {
  [Country.SWEDEN]:
    "https://support.worldline.com/sv-se/home/merchants/agreements/terms-and-conditions",
  [Country.DENMARK]:
    "https://support.worldline.com/da-dk/home/merchants/agreements/terms-and-conditions",
  [Country.FINLAND]:
    "https://support.worldline.com/fi-fi/home/merchants/agreements/terms-and-conditions",
  [Country.NORWAY]:
    "https://support.worldline.com/no-no/home/merchants/agreements/terms-and-conditions",
  // TODO: add GB
  [Country.GB]: "",
};

export function getFormattedValue(
  priceItem: SalesForcePriceEntry,
  country: Country,
  lang: Language
) {
  if (priceItem.unitType === UnitType.CURRENCY) {
    return (
      <FormattedPrice
        value={priceItem.value}
        currency={Currency[country]}
        locale={lang}
      />
    );
  }

  return <FormattedPercent value={priceItem.value} locale={lang} />;
}

export const CheckoutSummary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { search, setSearch } = useContext(GlobalContext);
  const [terms, setTerms] = useState<true>();

  const country = search[QueryKey.COUNTRY] as Country;
  const campaignId = search[QueryKey.CAMPAIGN_ID] as Country;

  const { currency, prices } = useCheckoutPricing(country, campaignId);

  // let total = AVAILABLE_TERMINALS.reduce((acc, cur) => {
  //   return acc + (search[cur] || 0) * (prices[cur]?.value ?? 0);
  // }, 0);

  // const extraPerTerminal = prices.extras.reduce((acc, cur) => {
  //   if (cur.unitType !== UnitType.CURRENCY) {
  //     return acc;
  //   }

  //   return acc + cur.value;
  // }, 0);

  const totalNumberOfTerminals = AVAILABLE_TERMINALS.reduce((acc, cur) => {
    return acc + (search[cur] ?? 0);
  }, 0);

  // total = total + extraPerTerminal * totalNumberOfTerminals;

  return (
    <div>
      <div className={styles.productsWrapper}>
        <div className="flex-column gap-4">
          {/* {count === 0 && (
            <div className="text-center">
              <div className="text-600">{t("Your cart is empty")}</div>
              <p>{t("Add something to your cart to see it here")}</p>
              <HiddenInput
                validators={[
                  new RequiredValidator(
                    t("Select at least one terminal to proceed")
                  ),
                ]}
                value={count ? true : undefined}
              />
            </div>
          )} */}
          {AVAILABLE_TERMINALS.map((value) => {
            // if (!search[value]) {
            //   return null;
            // }

            return (
              <ProductOffer
                key={value}
                terminalType={value}
                quantity={search[value] ?? 0}
                price={prices[value]?.value}
                discount={prices[value]?.discount}
                currency={currency}
                country={country}
                onClick={
                  search[QueryKey.SESSION]
                    ? undefined
                    : (quantity) => {
                        setSearch({
                          ...search,
                          [value]: quantity,
                        });
                        Store.setValue(STORE_KEY.STORE_SAVED_QUERY, {
                          ...search,
                          [value]: quantity,
                        });
                      }
                }
                // voucher={
                //   <Chip status={Status.SUCCESS} focusAlign={FocusAlign.LEFT}>
                //     <Chip.Focus>20%</Chip.Focus>
                //     <span className={styles.terminalName}>
                //       {t("Early bird discount")}
                //     </span>
                //   </Chip>
                // }
              />
            );
          })}
        </div>
        {/* <div>
          <LinkButton variant="text" to={OnboardingPath.PRODUCT_SELECTION}>
            <FaPlus /> {t("Add more terminals")}
          </LinkButton>
        </div> */}
      </div>

      <AdditionalCosts country={country} prices={prices} />

      <div className="mt-3">
        <Acquiring prices={prices} country={country} />
      </div>

      {/* <div className="flex-column gap-1 mt-4"> */}
      {/* <div className={styles.voucher}>
          <div className={styles.voucherInput}>
            <TextInput
              onChange={(value) =>
                setSearch({ ...search, [QueryKey.VOUCHER]: value })
              }
              value={search[QueryKey.VOUCHER]}
              label={t("Voucher code")}
            />
          </div>
          <Button
            onClick={() => {
              // TODO: handle voucher code
            }}
            variant="outlined"
            className={styles.voucherButton}
          >
            {t("Apply")}
          </Button>
        </div> */}
      {/* <div className={cx(styles.summary, "text-400")}>
          <div>{t("Subtotal")}</div>
          <span>
            <FormattedPrice
              value={total - totalVat}
              currency={Currency[country]}
              locale={i18n.language}
            />
          </span>
        </div>
        <div className={cx(styles.summary, "text-400")}>
          <div>
            {t("Tax rate {{rate}}%", {
              rate: vatRate,
            })}
          </div>
          <span>
            <FormattedPrice
              value={totalVat}
              currency={Currency[country]}
              locale={i18n.language}
            />
          </span>
        </div> */}
      {/* <div className={cx(styles.summary, "text-600")}>
          <div>{t("Total")}</div>
          <span>
            <FormattedPrice
              value={total}
              currency={Currency[country]}
              locale={i18n.language}
            />
          </span>
        </div> */}
      {/* </div> */}

      <hr className="mt-4" />

      <div className="mt-4">
        <TermsOfService currency={currency} />
      </div>

      <div className="mt-2">
        <HiddenInput
          value={totalNumberOfTerminals === 0 ? undefined : true}
          validators={[
            new RequiredValidator(t("You must select at least one terminal")),
          ]}
        />

        <HiddenInput
          value={terms ? true : undefined}
          validators={[new RequiredValidator(t("You must agree to the terms"))]}
        />
        <Checkboxes
          options={[
            {
              value: "terms",
              text: (
                <>
                  {t("I agree to the")}{" "}
                  <Link to={TERMS_AND_CONDITIONS_URL[country]} external>
                    {t("terms and conditions")}
                  </Link>
                </>
              ),
            },
          ]}
          values={terms ? ["terms"] : []}
          onChange={(values) => {
            setTerms(values.includes("terms") ? true : undefined);
          }}
        />
      </div>
    </div>
  );
};
