import { useMemo } from "react";
import styles from "./PhoneNumberCountry.module.scss";
import {
  getCountryFromPhoneNumber,
  PhoneValidatorResponseCountry,
  PhoneValidatorResponseName,
} from "./PhoneToCountry";
import { Flag } from "../../components/Countries/Flag";

interface Props {
  phoneNumber?: string;
}

const MIN_NUMBER_OF_CHARS = 3;

export const PhoneNumberCountry = ({ phoneNumber }: Props) => {
  const country = useMemo(() => {
    if (!phoneNumber) {
      return;
    }

    if (phoneNumber.length < MIN_NUMBER_OF_CHARS) {
      return;
    }

    const searchItem = getCountryFromPhoneNumber(phoneNumber);

    if (!searchItem.valid) {
      // return getCountryNameFromPhoneNumber(
      //   phoneNumber,
      //   Math.max(phoneNumber.length, 5)
      // );

      return null;
    }

    const phoneCountry =
      (searchItem as PhoneValidatorResponseCountry).country ||
      (searchItem as PhoneValidatorResponseName).name;

    return (
      <div className={styles.flag}>
        <Flag country={phoneCountry} height={20} />
      </div>
    );
  }, [phoneNumber]);

  return <>{country}</>;
};
