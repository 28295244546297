import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Banner.module.scss";
import { MdInfoOutline, MdTaskAlt, MdWarningAmber } from "react-icons/md";
import { Status } from "../../modules/Forms/FormContext";

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  status?: Status;
  onClose?: () => void;
  className?: string;
}

const iconMapping: Record<Status, ReactNode> = {
  [Status.DISABLED]: null,
  [Status.PENDING]: null,
  [Status.DEFAULT]: <MdInfoOutline />,
  [Status.ERROR]: <MdInfoOutline />,
  [Status.SUCCESS]: <MdTaskAlt />,
  [Status.WARNING]: <MdWarningAmber />,
};

export const Banner: React.FunctionComponent<Props> = ({
  children,
  status = Status.DEFAULT,
  className,
  icon,
}) => {
  return (
    <div className={cx(styles.banner, "small", className, styles[status])}>
      <div className={styles.icon}>{icon || iconMapping[status]}</div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
